import React, { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"

import moment from "moment"

import { useRequest, useRequestBody } from "api/hooks"
import { roleConstants } from "constants/index"

import { AccessTemplates } from "store/access/types"

import { getTemplatesList } from "store/access/reducer"

import PaginationComponent from "Components/Pagination"
import TooltipComponent from "Components/Tooltip"

import { ReactComponent as DeleteIcon } from "Components/icons/delete_red.svg"
import { ReactComponent as EditIcon } from "Components/icons/crud/edit_20.svg"
import { ReactComponent as PauseInfoIcon } from "Components/icons/info_red.svg"

import { CardsList, CardsListData } from "./types"
import { accessCardsInitialData, accessCardText } from "./constants"

import RemoveModal from "./remove"

import { AccessFiltersData, AccessStatuses } from "../types"

const AccessCards = ({
  filters,
  openModal,
  refreshTrigger,
  refresh
}: {
  filters: AccessFiltersData
  openModal: (data?: CardsListData) => void
  refreshTrigger: number
  refresh: () => void
}) => {
  const templates = useSelector(getTemplatesList)
  const [cards, setCards] = useState<CardsList>(accessCardsInitialData)
  const isEmptyList = useMemo(() => cards.total_count === 0, [
    cards.total_count
  ])

  const { pagination, setPagination } = useRequestBody()
  const { request: getCardsData } = useRequest({
    url: accessCardText.url.data,
    requestBody: {
      filter: { ...filters, status: filters.status || undefined },
      pagination
    }
  })

  const request = () => {
    setCards({ ...cards, loading: true })
    getCardsData()
      .then(({ data }) => {
        setCards({
          data: data.items,
          loading: false,
          total_count: data.totalCount
        })
      })
      .catch(() => {
        setCards({ ...cards, loading: false })
      })
  }

  useEffect(() => {
    request()
  }, [filters, pagination, refreshTrigger])

  const [removeData, setRemoveData] = useState<CardsListData | undefined>(
    undefined
  )
  const { isSupport } = roleConstants()

  return (
    <div>
      {!!removeData && (
        <RemoveModal
          data={removeData}
          close={(withRefresh: boolean) => {
            setRemoveData(undefined)
            if (withRefresh) {
              refresh()
            }
          }}
        />
      )}
      {!cards.loading && isEmptyList && accessCardText.emptyList}
      {!cards.loading && !isEmptyList && (
        <div>
          <div className="AccessCardsWrapper FlexRow FlexWrap Gap16">
            {cards.data.map(item => {
              const currentTemplate = templates.find(
                findItem => findItem.key === item.template_name
              )
              const isPaused = item.status === AccessStatuses.paused
              const pauseTitle = item.status_auto_changed
                ? accessCardText.card.pause.auto
                : accessCardText.card.pause.manual
              const canEdit = !(
                isSupport && item.template_name === AccessTemplates.by_request
              )
              return (
                <div key={item.id} className="AccessCardsItem FlexColumn Gap8">
                  <div className="TextTwo AccessCardsItemFullName">
                    {item.full_name}
                  </div>
                  <div className="FlexRow Gap8">
                    <span className="TextOne Gray600Color PaddingTop2">
                      {accessCardText.card.login}
                    </span>
                    <span className="TextTwo AccessCardsItemLogin">
                      {item.login}
                    </span>
                  </div>
                  <div className="FlexRow Gap8">
                    <span className="TextOne Gray600Color PaddingTop2">
                      {accessCardText.card.phone}
                    </span>
                    <span className="TextTwo">{item.phone}</span>
                  </div>
                  <div className="FlexRow Gap8">
                    <span className="TextOne Gray600Color PaddingTop2">
                      {accessCardText.card.created_date}
                    </span>
                    <span className="TextTwo">
                      {moment(item.created_date).format(
                        accessCardText.card.format
                      )}
                    </span>
                  </div>
                  <div className="FlexRow Gap8">
                    <span className="TextOne Gray600Color PaddingTop2">
                      {accessCardText.card.last_auth}
                    </span>
                    <span className="TextTwo">
                      {!!item.last_auth
                        ? moment(item.last_auth).format(
                            accessCardText.card.format
                          )
                        : accessCardText.card.empty}
                    </span>
                  </div>
                  <div className="FlexRow Gap8">
                    <span className="TextOne Gray600Color PaddingTop2">
                      {accessCardText.card.template_name}
                    </span>
                    <span className="TextTwo">
                      {currentTemplate?.name ||
                        item.custom_template_name ||
                        accessCardText.card.empty}
                    </span>
                  </div>
                  <div className="FlexRow Gap8">
                    <span className="TextOne Gray600Color PaddingTop2">
                      {accessCardText.card.halls}
                    </span>
                    <span className="TextTwo AccessCardsItemHalls">
                      {item.halls.join(`, `)}
                    </span>
                  </div>
                  <div className="FlexRow PaddingTop8 MarginTopAuto">
                    <div
                      className={`AccessCardsItemStatus AccessCardsItemStatus_${item.status} FlexRow MarginTop4`}
                    >
                      <span className="StatusText">
                        {accessCardText.card.status[item.status]}
                      </span>
                    </div>
                    {isPaused && (
                      <div className="AccessCardsItemStatusTooltip MarginTop4 Padding6">
                        <TooltipComponent title={pauseTitle}>
                          <PauseInfoIcon />
                        </TooltipComponent>
                      </div>
                    )}
                    {canEdit && (
                      <div className="MarginLeftAuto FlexRow Gap8">
                        <div
                          className="AccessCardsItemButton AccessCardsItemButtonDelete"
                          onClick={() => openModal(item)}
                        >
                          <EditIcon />
                        </div>
                        <div
                          className="AccessCardsItemButton"
                          onClick={() => {
                            setRemoveData(item)
                          }}
                        >
                          <DeleteIcon />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
          <PaginationComponent
            value={pagination}
            onChange={setPagination}
            total={cards.total_count}
          />
        </div>
      )}
    </div>
  )
}

export default AccessCards
